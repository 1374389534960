import type { CSSProperties } from 'styled-components'
import styled from 'styled-components'

import type { TypographySizeType } from '../../types/typography/typographySizes'

export const BodyTextStyled = styled.span<{
  $color: CSSProperties['color']
  $size: TypographySizeType
}>`
  color: ${p => p.$color};
  ${p => p.theme.typography.desktop.body[p.$size].$value}

  ${p => p.theme.mediaQueries.mobile} {
    ${p => p.theme.typography.mobile.body[p.$size].$value}
  }
`
