import BodyText from 'firebird-ui/src/components/BodyText'
import Link from 'next/link'
import styled from 'styled-components'

import ArrowUpRightIcon from '@iconscout/unicons/svg/line/arrow-up-right.svg'

export const Container = styled.li`
  flex: 1;
  border-radius: ${p => p.theme.borderRadius.sm};

  &:nth-child(1) {
    border: 1px solid ${p => p.theme.colors.Surface10.$value};
  }

  &:nth-child(2) {
    background-color: ${p => p.theme.colors.Brand10.$value};
  }

  &:nth-child(3) {
    background-color: ${p => p.theme.colors.Surface10.$value};
  }

  ${p => p.theme.mediaQueries.mobile} {
    &:nth-child(1) {
      grid-column-end: span 2;
      padding-block: 4px;
    }
  }
`

export const StyledLink = styled(Link)`
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
  padding: 14px 24px;
  width: 100%;

  ${p => p.theme.mediaQueries.mobile} {
    padding: 12px 16px;
  }
`

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Cation = styled(BodyText)`
  color: ${p => p.theme.colors.Brand100.$value};
`

export const Description = styled(BodyText)`
  &,
  * {
    color: ${p => p.theme.colors.onBackground.$value};
  }
`

export const Icon = styled(ArrowUpRightIcon)<{ $iconUrl?: false | string }>`
  height: 24px;
  width: 24px;
  background: center / cover no-repeat url('${p => p.$iconUrl}');
  fill: ${p => (p.$iconUrl ? 'transparent' : p.theme.colors.Brand100.$value)};
  position: relative;
  top: -4px;
  inset-inline-end: -14px;

  ${p => p.theme.mediaQueries.tablet} {
    inset-inline-end: 0;
  }

  ${p => p.theme.mediaQueries.mobile} {
    inset-inline-end: -4px;
  }
`
