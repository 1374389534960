import dynamic from 'next/dynamic'
import type { FC, SyntheticEvent } from 'react'

import { useGetParagraphQuery } from '@redux/features/homePage/api'
import { useAppSelector } from '@redux/hooks'
import { selectIsPageInteraction } from '@reduxCommonApp/commonApp.selectors'

import useToggle from '@hooks/useToggle/useToggle'

import { Cation, Container, Description, Icon, StyledLink, Top } from './styles'

const MobileAppModal = dynamic(() => import('@components/modals/MobileApp'))

type Props = {
  itemId: number
}

export const ListItem: FC<Props> = ({ itemId }) => {
  const { on: open, toggleOff, toggleOn } = useToggle(false)
  const isPageInteraction = useAppSelector(selectIsPageInteraction)
  const { data } = useGetParagraphQuery(itemId)
  const isPopup = data?.action === 'popup'

  const onCardClick = (e: SyntheticEvent) => {
    e.preventDefault()
    toggleOn()
  }

  return (
    <Container>
      <StyledLink href={data?.link?.uri || ''} onClick={isPopup ? onCardClick : undefined}>
        <Top>
          <Cation size="xsmall-strong">{data?.title}</Cation>
          <Icon $iconUrl={data?.icon && data.icon.uri.url} />
        </Top>
        <Description dangerouslySetInnerHTML={{ __html: data?.description || '' }} size="small-strong" />
      </StyledLink>
      {isPageInteraction && isPopup && data?.popup_block && (
        <MobileAppModal blockId={data.popup_block} onClose={toggleOff} open={open} />
      )}
    </Container>
  )
}
