import type { FC } from 'react'

import type { ExtendBlocksType } from '@Types/blocks/blocks'
import type { DisclaimerType } from '@Types/blocks/disclaimer'

import ListItem from './components/ListItem'
import { BodyWithBenefitsContainer, Description, List } from './styles'

type Props = {
  data: ExtendBlocksType
}

export const BodyWithBenefits: FC<Props> = ({ data }) => {
  const { benefits, body } = data as DisclaimerType

  return (
    <BodyWithBenefitsContainer data-block={data.type}>
      <Description dangerouslySetInnerHTML={{ __html: body.value }} />

      <List>
        {benefits.map(itemId => (
          <ListItem itemId={itemId} key={itemId} />
        ))}
      </List>
    </BodyWithBenefitsContainer>
  )
}
