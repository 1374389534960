import type { TextProps } from 'antd/es/typography/Text'
import { type DOMAttributes, type ReactNode, forwardRef } from 'react'
import type { CSSProperties } from 'styled-components'

import type { TypographySizeType } from '../../types/typography/typographySizes'
import { BodyTextStyled } from './styles'

type TypographyTextProps = { color?: CSSProperties['color']; size?: TypographySizeType; tag?: string } & (
  | { children: ReactNode }
  | { dangerouslySetInnerHTML: Required<DOMAttributes<HTMLElement>['dangerouslySetInnerHTML']> }
) &
  TextProps

export const BodyText = forwardRef<HTMLSpanElement, TypographyTextProps>(
  ({ children, color = 'inherit', size = 'medium', tag = 'span', ...props }, ref) => {
    if (props.dangerouslySetInnerHTML) {
      return <BodyTextStyled ref={ref} {...props} $color={color} $size={size} as={tag} />
    }

    return (
      <BodyTextStyled ref={ref} {...props} $color={color} $size={size} as={tag}>
        {children}
      </BodyTextStyled>
    )
  }
)

BodyText.displayName = 'BodyText'
